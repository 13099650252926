<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-2"
      >
        <div class="m-2">
          <b-row>
            <b-col>
              <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="IMPORT WEB :"
                label-for="input-lg"
              >
                <b-input-group>
                  <b-form-input
                    id="input-lg"
                    v-model="inputUrl"
                    placeholder="URL IMPORT"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click="getData"
                    >
                      <span class="mr-25 align-middle">{{
                        $t('GET_FROM_URL')
                      }}</span>
                      <feather-icon
                        icon="GlobeIcon"
                        size="12"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="IMPORT JSON :"
                label-for="input-lg"
              >
                <b-form-file
                  ref="uploadFileJson"
                  class="mb-2"
                  @change="updateFiles"
                />
              </b-form-group>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                @click="clearData"
              >
                <feather-icon
                  icon="RefreshIcon"
                  class="mr-50"
                />
                <span class="align-middle">{{ $t('Reset') }}</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
    <div v-if="valueProduct != 0">
      <label>{{ $t('Product') }} {{ valueProduct }} / {{ maxProduct }} ({{
        nameProduct
      }})</label>
      <b-progress :max="maxProduct">
        <b-progress-bar :value="valueProduct">
          <strong>{{ valueProduct }} / {{ maxProduct }}</strong>
        </b-progress-bar>
      </b-progress>
    </div>
    <div
      v-if="valueImage != 0"
      class="mb-2"
    >
      <label>{{ $t('Image') }} {{ valueImage }} / {{ maxImage }} ({{
        nameImage
      }})</label>
      <b-progress
        :max="maxImage"
        class="progress-bar-info"
      >
        <b-progress-bar
          :value="valueImage"
          variant="info"
        >
          <strong>{{ valueImage }} / {{ maxImage }}</strong>
        </b-progress-bar>
      </b-progress>
    </div>
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row>
            <b-col>
              <label>{{ $t('Data') }} {{ queriedItems }} {{ $t('List') }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector v-select-custom"
                @input="pageSize"
              />
            </b-col>

            <b-col
              cols="12"
              md="6"
              class="b-col-custom"
            >
              <div class="d-flex align-items-center justify-content-end">
                <!-- <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search')+'...'"
                /> -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="d-flex"
                  @click="selectAllRows"
                >
                  <feather-icon icon="ListIcon" />
                  <span class="mr-25 align-middle">{{ $t('SELECT_ALL') }}</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="d-flex ml-2"
                  @click="clearSelected"
                >
                  <feather-icon icon="XIcon" />
                  <span class="mr-25 align-middle">{{
                    $t('CLEAR_SELECTED')
                  }}</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="d-flex ml-2"
                  @click="importData"
                >
                  <feather-icon icon="PlusIcon" />
                  <span class="mr-25 align-middle">{{ $t('IMPORT') }}</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="d-flex ml-2"
                  @click="importAllProduct"
                >
                  <feather-icon icon="PlusIcon" />
                  <span class="mr-25 align-middle">{{ $t('IMPORT_ALL') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="selectableTable"
          striped
          responsive
          class="position-relative"
          :items="respData"
          :select-mode="selectMode"
          :fields="tableColumns"
          :per-page="perPage"
          :current-page="currentPage"
          primary-key="products_id"
          show-empty
          :empty-text="$t('No matching records found')"
          selectable
          style="height: 60vh;"
          @row-selected="onRowSelected"
        >
          <!-- Example scoped slot for select state illustrative purposes -->
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
          <!-- Column: Images -->
          <template #cell(products_image)="data">
            <b-row>
              <b-img
                v-if="data.item.products_image.length > 0"
                :src="
                  'https://gooapp.quinlcdn.com/' + data.item.products_image[0]
                "
                fluid
                thumbnail
                width="100px"
                :alt="'Product_' + data.item.products_id"
              />
            </b-row>
          </template>
          <!-- Column: Name -->
          <template #cell(productslang_name)="data">
            <b-row>
              {{ data.item.productslang_name }}
            </b-row>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span
                class="text-muted"
              >{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                    ? currentPage * perPage
                    : queriedItems
                }}
                {{ $t('of') }} {{ queriedItems }}</span>
            </b-col>
          </b-row>
        </div>
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="queriedItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @input="nextPage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-card>
    </b-overlay>
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      :title="$t('IMPORT_DATA')"
      :ok-title="$t('IMPORT')"
      cancel-variant="outline-secondary"
      @ok="importData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BPagination,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BFormFile,
  BButton,
  BTable,
  BOverlay,
  BModal,
  VBModal,
  BProgress,
  BProgressBar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// import { useToast } from 'vue-toastification/composition'
import perPageOptions from '@/perPageOptions'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/eCommerce/product'
import axios from 'axios'

// const https = require('https')

const STORE_MODULE_NAME = 'product'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BPagination,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormFile,
    BButton,
    // Sidebar,
    BTable,
    BOverlay,
    BModal,
    BProgress,
    BProgressBar,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      avatarText,
      inputUrl:
        'https://api.gooapp.me/gooweb/api_startapp?site_id=34186&cmd=getproducts&token=44eeWqqaasggGHasdfasdfas',
      respData: [],
      perPageOptions,
      show: false,
      queriedItems: 0,
      currentPage: 1,
      perPage: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 50,
      totalRows: 1,
      selectMode: 'multi',
      selected: [],
      nameProduct: '',
      valueProduct: 0,
      maxProduct: 0,
      nameImage: '',
      valueImage: 0,
      maxImage: 0,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'selected', label: this.$t('SELECTED'), sortable: false },
        { key: 'products_image', label: this.$t('IMAGE'), sortable: false },
        { key: 'products_id', label: this.$t('ID'), sortable: true },
        { key: 'productslang_name', label: this.$t('NAME'), sortable: false },
        {
          key: 'productpcategory_pcategory_id',
          label: this.$t('ID_CATEGORY'),
          sortable: true,
        },
        { key: 'products_price', label: this.$t('PRICE'), sortable: false },
        // { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    wsClient() {
      return store.state.websockets.wsClient[STORE_MODULE_NAME]
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }
    const userData = JSON.parse(localStorage.getItem('userData'))
    const applicationId = localStorage.getItem('applicationId')
    // WebSocket Connect
    const pathname = `/Products?type=${userData.userType}&userId=${userData.userId}&applicationId=${applicationId}`
    const params = {
      pathname,
      page: STORE_MODULE_NAME,
    }
    store.dispatch('websockets/connect', params)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME)
    }
    store.dispatch('websockets/disconnect', STORE_MODULE_NAME)
  },
  beforeMount() {
    // WebSocket
    if (this.wsClient) {
      // ถ้าเกิดเชื่อมไม่ได้ จะเข้า onErrorก่อน หลังจากนั้นจะทำการ onClose เอง
      this.wsClient.onerror = err => {
        console.log('onError')
        console.log(err)
      }
      this.wsClient.onclose = err => {
        console.log('onClose')
        console.log(err)
      }
      this.wsClient.onmessage = msg => {
        const SocketMessage = JSON.parse(msg.data)
        //
        // console.log(SocketMessage)
        if (SocketMessage.data.send) {
          if (SocketMessage.data.send.data.message === 'IMPORT_PRODUCT_IMAGE') {
            this.show = true
            this.nameImage = SocketMessage.data.send.data.data.nameImage
            this.valueImage = SocketMessage.data.send.data.data.currentImage
            this.maxImage = SocketMessage.data.send.data.data.maxImage
            this.nameProduct = SocketMessage.data.send.data.data.nameProduct
            this.valueProduct = SocketMessage.data.send.data.data.currentProduct
            this.maxProduct = SocketMessage.data.send.data.data.maxProduct
            if (this.valueProduct === this.maxProduct) {
              this.show = false
            }
          }
        }

        // this.adminOnline = SocketMessage.data.web.admin.length
        // this.rootOnline = SocketMessage.data.web.root.length
      }
    }
  },
  methods: {
    importAllProduct() {
      this.$bvModal
        .msgBoxConfirm(
          `${this.$t('Do you want to import all product')} ${
            this.respData.length
          } ${this.$t('List')}?`,
          {
            title: this.$t('Please Confirm'),
            cancelVariant: 'outline-secondary',
            okVariant: 'success',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            centered: true,
          },
        )
        .then(value => {
          if (value === true) {
            if (this.respData.length > 0) {
              const obj = {
                dataSelected: this.respData,
              }
              this.show = true
              store
                .dispatch(`${STORE_MODULE_NAME}/import`, obj)
                .then(() => {
                  this.show = false

                  this.showToast(
                    'warning',
                    'top-right',
                    `${this.$t('IMPORT_DATA')}! ${
                      this.respData.length
                    } ${this.$t('List')}`,
                    'PlusIcon',
                    this.$t('IMPORT_DATA'),
                  )
                })
                .catch(error => {
                  this.show = false

                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                      title: this.$t('Error'),
                      icon: 'ErrorIcon',
                      variant: 'danger',
                      text: this.$t(error.response.data.message),
                    },
                  })
                })
            }
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    importData() {
      this.$bvModal
        .msgBoxConfirm(
          `${this.$t('Do you want to import all product')} ${
            this.selected.length
          } ${this.$t('List')}?`,
          {
            title: this.$t('Please Confirm'),
            cancelVariant: 'outline-secondary',
            okVariant: 'success',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            centered: true,
          },
        )
        .then(value => {
          if (value === true) {
            if (this.selected.length > 0) {
              const obj = {
                dataSelected: this.selected,
              }
              this.show = true
              store
                .dispatch(`${STORE_MODULE_NAME}/import`, obj)
                .then(() => {
                  this.show = false

                  this.showToast(
                    'warning',
                    'top-right',
                    `${this.$t('IMPORT_DATA')}!`,
                    'PlusIcon',
                    this.$t('IMPORT_DATA'),
                  )
                })
                .catch(error => {
                  this.show = false

                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                      title: this.$t('Error'),
                      icon: 'ErrorIcon',
                      variant: 'danger',
                      text: this.$t(error.response.data.message),
                    },
                  })
                })
            } else {
              this.showToast(
                'danger',
                'top-right',
                `${this.$t('NOT_SELECT_DATA')}!`,
                'XIcon',
                this.$t('NOT_SELECT_DATA'),
              )
            }
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
      // this.selected = this.respData
      // console.log(this.selected)
    },
    clearSelected() {
      // this.$refs.selectableTable.clearSelected()
      this.selected = []
    },
    onRowSelected(items) {
      this.selected = items
    },
    clearData() {
      this.inputUrl = ''
      this.currentPage = 1
      this.respData = []
      this.selected = []
      this.queriedItems = 0
      this.$refs.uploadFileJson.reset()
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.perPage = size
    },
    nextPage(page) {
      this.currentPage = page
    },
    getData() {
      this.show = true

      axios({
        method: 'get',
        url: this.inputUrl.trim(),
        responseType: 'stream',
      })
        .then(response => {
          const list = []
          if (response.data.length > 0) {
            for (let index = 0; index < response.data.length; index += 1) {
              list.push({
                products_image: response.data[index].products_image
                  ? JSON.parse(response.data[index].products_image)
                  : [],
                products_id: response.data[index].products_id,
                productslang_name: response.data[index].productslang_name,
                productpcategory_pcategory_id: response.data[index].pcategory_id
                  ? `[${response.data[index].pcategory_id}]`
                  : '[]', // ยังไม่มี
                products_price: response.data[index].products_price,
                products_weight: response.data[index].products_weight,
                promotion_discount: response.data[index].promotion_discount, // ยังไม่มี
                productslang_detail: response.data[index].productslang_detail,
              })
            }
            this.respData = list
            this.queriedItems = list.length
            this.show = false
            this.showToast(
              'warning',
              'top-right',
              `${this.$t('GET_DATA')}!`,
              'PlusIcon',
              this.$t('GET_DATA'),
            )
          } else {
            this.show = false
            this.showToast(
              'warning',
              'top-right',
              `${this.$t('NOT_FOUND_DATA')}!`,
              'PlusIcon',
              this.$t('NOT_FOUND_DATA'),
            )
          }
        })
        .catch(err => {
          if (err) {
            console.log('ERROR MSG: ', err)
          }
          this.show = false
        })
    },
    updateFiles(input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          const list = []

          const response = { data: JSON.parse(e.target.result) }
          for (let index = 0; index < response.data.length; index += 1) {
            list.push({
              products_image: response.data[index].products_image
                ? JSON.parse(response.data[index].products_image)
                : [],
              products_id: response.data[index].products_id,
              productslang_name: response.data[index].productslang_name,
              productpcategory_pcategory_id: response.data[index].pcategory_id
                ? `[${response.data[index].pcategory_id}]`
                : '[]', // ยังไม่มี
              products_price: response.data[index].products_price,
              products_weight: response.data[index].products_weight,
              promotion_discount: response.data[index].promotion_discount, // ยังไม่มี
              productslang_detail: response.data[index].productslang_detail,
            })
          }
          this.respData = list
          console.log(this.respData)

          this.queriedItems = this.respData.length
        }
        reader.readAsText(input.target.files[0])
      }
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
